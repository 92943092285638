import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { CallService } from 'src/@shared/services/call.service';
import { SharedService } from 'src/@shared/services/shared.service';
import { SupersetService } from 'src/@shared/services/superset.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  storedWindowObj: any;

  constructor(
    private title: Title,
    private supersetService: SupersetService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), 
      map(() => {
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data) {
            return child.snapshot.data;
          } else {
            return null;
          }
        }

        return null;
      }),
    ).subscribe((routeData: any) => {
      console.log('customData ', routeData);
      this.sharedService.openSidebar();
      
      if (routeData?.isShowPreviousPageBtn) {
        this.sharedService.showPreviousPageBtn();
      } else {
        this.sharedService.hidePreviousPageBtn();
      }
    });
  }
  
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.storedWindowObj = this.supersetService.storedWindowObj;
    for (let i = 0; i < this.storedWindowObj?.length; i++) {
      if (this.storedWindowObj[i]) {
        this.storedWindowObj[i].close();
      }
    }
  }

  ngOnInit() {
    this.title.setTitle(environment.title || 'AuditNex');
  }
}
