import { Injector, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HeaderInterceptor } from 'src/@shared/interceptors/header.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { oktaConfig } from 'src/@shared/configs/okta-config';
import { Router } from '@angular/router';
import { NexScreenLoaderComponent } from 'src/@shared/components/nex-screen-loader/nex-screen-loader.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        OktaAuthModule,
        NexScreenLoaderComponent,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        {
            provide: OKTA_CONFIG,
            useFactory: () => {
                const oktaAuth = new OktaAuth(oktaConfig);
                return {
                    oktaAuth,
                    onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
                        const triggerLogin = () => {
                            const router = injector.get(Router);
                            router.navigateByUrl('/auth/login');
                        };
                        if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                            triggerLogin();
                        }
                        else {
                            const isReAuth = confirm('Do you want to re-authenticate?');
                            if (isReAuth) {
                                triggerLogin();
                            }
                        }
                    }
                };
            }
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync()
    ] })
export class AppModule {}
