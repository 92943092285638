/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, WritableSignal, signal } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Login } from '../interfaces/login';
import { from, map, of, take, tap } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import { AuthType } from '../enums/authType.enum';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { SharedService } from './shared.service';
import { RoleMapping } from '../interfaces/roleMapping';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public user: WritableSignal<User> = signal<User>({ id: 0, firstname: '', lastname: '', email: '', img: '', gender: '', ssoUserType: '', userType: 'Admin', groupIds: [], processIds: [], companyLogo: '', productLogo: '', productIcon: '', showCompanyLogo: '',showProductLogo: '', count: [] });
  authType: AuthType = AuthType.okta;
  tokenKey = 'accessToken';

  constructor(
    private router: Router,
    private commonService: CommonService,
    private sharedService: SharedService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.getAuthType();
  }

  login(reqBody: Login): Observable<User> {
    return this.commonService.post<User>('auth/login', { credentials: btoa(JSON.stringify(reqBody)) }).pipe(tap((res: User) => {
      console.log('res : ', res);
      
      if (res?.id) {
        localStorage.setItem(this.tokenKey, res?.accessToken || '');
      }
    }));
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();

    this.setAuthType(this.authType);
    if (this.authType === AuthType.okta) {
      this.oktaAuth.signOut().finally(() => {
        window.location.href = '/auth/login';
      });
    } else if (this.authType === AuthType.contiinex) {
      // this.router.navigateByUrl('/auth/nex-login');
      window.location.href = '/auth/nex-login';
      // this.commonService.get('auth/logout').pipe(take(1)).subscribe( () => {
      // });
    }
  }

  register(reqBody: User): Observable<any> {
    return this.commonService.post<any>('auth/register', reqBody);
  }

  ssoRegister(reqBody: User): Observable<any> {
    return this.commonService.post<any>('auth/ssoRegister', reqBody);
  }

  refreshToken(): Observable<{ accessToken: string }> {
    return this.commonService.post<{ accessToken: string }>('auth/refreshToken', { token: this.getToken() }).pipe(tap((res: { accessToken: string }) => {
      if (res?.accessToken) {
        localStorage.setItem(this.tokenKey, res?.accessToken || '');
      }
    }));
  }

  getToken(): string {
    if (this.authType === AuthType.okta) {
      return this.oktaAuth.getAccessToken() || '';
    } else if (this.authType === AuthType.contiinex) {
      return localStorage.getItem(this.tokenKey) || '';
    }

    return '';
  }

  isAuthorized(): boolean {
    return this.getToken() !== '';
  }

  setUser(user: User): void {
    const roleMapping = this.sharedService.roleMapping() || [];
    if ((user.ssoUserType || user.userType) && roleMapping.length > 0) {
      const mapping = roleMapping.find((val) => {
        if (this.authType === AuthType.contiinex) { 
          return val.userType.toLowerCase() === user.userType.toLowerCase()
        }

        return val.ssoUserType.toLowerCase() === user.ssoUserType.toLowerCase();
      });

      if (mapping?.id) {
        user.userType = mapping.userType;
        localStorage.setItem('mapping', JSON.stringify(mapping));
      }
    }

    localStorage.setItem('user', JSON.stringify(user));
    this.user.set(user);
  }

  getAndSetUser(): Observable<User> {
    const user = JSON.parse((localStorage.getItem('user') || '{}')) as User;
    if (user?.id) {
      this.setUser(user);
    }

    if (this.authType === AuthType.okta) {
      return from(this.oktaAuth.getUser()).pipe(map((res: UserClaims) => {
        console.log('okta user info : ', res);
        this.setUser({
          id: parseInt(res?.sub), firstname: res.given_name || '', lastname: res.family_name || '', email: res?.email || '', img: res?.['img']?.toString(), gender: res?.['gender']?.toString(), ssoUserType: (res?.['user_type'] || 'Staff')?.toString(), userType: 'Admin', groupIds: (res?.['groupIds'] as []) || [], processIds: (res?.['processIds'] as []) || [], companyLogo: res?.['companyLogo']?.toString(), productLogo: res?.['productLogo']?.toString(), productIcon: res?.['productIcon']?.toString(), showCompanyLogo: res?.['showCompanyLogo']?.toString(), showProductLogo: res?.['showProductLogo']?.toString(), count: (res?.['count'] as []) || []
        });

        return this.user();
      }));
    } else if (this.authType === AuthType.contiinex) { 
      return this.commonService.get<User>('auth/me').pipe(map((res: User) => {
        console.log('contiinex user info : ', res);
        if (res?.id) {
          this.setUser({
            id: res?.id, firstname: res?.firstname, lastname: res?.lastname, email: res?.email, img: res?.img, gender: res?.gender, ssoUserType: '', userType: res?.userType, groupIds: res?.groupIds || [], processIds: res?.processIds || [], companyLogo: res?.companyLogo, productLogo: res?.productLogo, productIcon: res?.productIcon, showCompanyLogo: res?.showCompanyLogo, showProductLogo: res?.showProductLogo, count: res?.count
          });
        }

        return this.user();
      }));
    } else {
      return of(this.user());
    }
  }

  setAuthType(type: AuthType): void {
    this.authType = type;
    localStorage.setItem('authType', type.toString());
  }

  getAuthType(): AuthType {
    this.authType = (parseInt(localStorage.getItem('authType') || '') || AuthType.contiinex) as AuthType;
    return this.authType;
  }

  getMapping(): RoleMapping {
    return JSON.parse(localStorage.getItem('mapping') || '{}') as RoleMapping;
  }

  forgotPassword(reqBody: string): Observable<any> {
    return this.commonService.post<User>('auth/forgotPassword', reqBody);
  }

  resetPassword(reqBody: any): Observable<any> {
    return this.commonService.post('auth/resetPassword', { credentials: btoa(JSON.stringify(reqBody)) });
  }

  changePassword(reqBody: any): Observable<any> {
    return this.commonService.post('auth/changePassword', { credentials: btoa(JSON.stringify(reqBody)) });
  }
}
