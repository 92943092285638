import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const unAuthGuard: CanActivateFn = () => {
  const isAuth = inject(AuthService).isAuthorized();

  if (isAuth) {
    return inject(Router).createUrlTree(['/dashboard/processes']);
  }

  return true;
};
