import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { rolePermissionConfig } from 'src/@shared/configs/role-permission-config';
import { authGuard } from 'src/@shared/guards/auth.guard';
import { nexLoginGuard } from 'src/@shared/guards/nex-login.guard';
import { roleGuard } from 'src/@shared/guards/role.guard';
import { unAuthGuard } from 'src/@shared/guards/un-auth.guard';
import { dashboardResolver } from 'src/@shared/resolves/dashboard.resolver';
import { environment } from 'src/environments/environment';

const loginUrl = environment.loginAllow.includes('contiinex') ? '/auth/nex-login' : '/auth/login';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: loginUrl,
  },
  {
    path: 'auth',
    canActivate: [unAuthGuard],
    loadComponent: () => import('./auth-layout/auth-layout.component').then((c) => c.AuthLayoutComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: loginUrl,
      },
      {
        path: 'login',
        loadComponent: () => import('./auth-layout/sso-login/sso-login.component').then((c) => c.SsoLoginComponent),
      },
      {
        path: 'nex-login',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/nex-login/nex-login.component').then((c) => c.NexLoginComponent),
      },
      {
        path: 'okta/callback',
        component: OktaCallbackComponent,
      },
      {
        path: 'forgot-password',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
      },
      {
        path: 'reset-password/:token',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
      },
    ],
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    resolve: { dashboardConfig: dashboardResolver },
    loadComponent: () => import('./dashboard-layout/dashboard-layout.component').then((c) => c.DashboardLayoutComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard/processes'
      },
      {
        path: 'processes',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.processes
        },
        loadChildren: () => [
          {
            path: '',
            loadComponent: () => import('./dashboard-layout/processes/processes.component').then((c) => c.ProcessesComponent),
          },
          {
            path: ':id',
            data: {
              isShowPreviousPageBtn: true
            },
            loadChildren: () => [
              {
                path: '',
                loadComponent: () => import('./dashboard-layout/processes/process-summary/process-summary.component').then((c) => c.ProcessSummaryComponent),
              },
              {
                path: 'calls',
                loadChildren: () => [
                  {
                    path: '',
                    loadComponent: () => import('./dashboard-layout/processes/process-summary/call-list/call-list.component').then((c) => c.CallListComponent),
                  },
                  {
                    path: ':callId',
                    loadComponent: () => import('./dashboard-layout/processes/process-summary/call-list/call-audit/call-audit.component').then((c) => c.CallAuditComponent),
                  },
                ]
              }
            ],

          },
        ]
      },
      {
        path: 'analytics',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.analytics
        },
        loadChildren: () => [
          {
            path: '',
            loadComponent: () => import('./dashboard-layout/analytics/analytics.component').then((c) => c.AnalyticsComponent),
          },
          {
            path: ':id',
            loadComponent: () => import('./dashboard-layout/analytics/analytics.component').then((c) => c.AnalyticsComponent),
          }
        ]
      },
      {
        path: 'audit-forms',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.auditForms
        },
        loadChildren: () => [
          {
            path: '',
            data: {
              isAddProcess: false,
              isAddTemplate: false,
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/audit-forms/add-edit-audit-form/add-edit-audit-form.component').then((c) => c.AddEditAuditFormComponent),
          },
          {
            path: 'add-process',
            data: {
              isAddProcess: true,
              isAddTemplate: false,
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/audit-forms/add-edit-audit-form/add-edit-audit-form.component').then((c) => c.AddEditAuditFormComponent),
          },
          {
            path: 'add-template',
            data: {
              isAddTemplate: true,
              isAddProcess: false,
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/audit-forms/add-edit-audit-form/add-edit-audit-form.component').then((c) => c.AddEditAuditFormComponent),
          },
          {
            path: 'edit/:id',
            data: {
              isAddProcess: false,
              isAddTemplate: false,
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/audit-forms/add-edit-audit-form/add-edit-audit-form.component').then((c) => c.AddEditAuditFormComponent),
          },
          {
            path: 'automate/:id',
            canActivate: [roleGuard],
            data: {
              permission: rolePermissionConfig.automate,
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/audit-forms/automate-form/automate-form.component').then((c) => c.AutomateFormComponent),
          },
        ]
      },
      {
        path: 'coach',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.coachNex
        },
        loadChildren: () => [
          {
            path: 'coach-nex',
            loadComponent: () => import('./dashboard-layout/coach-nex/coach-nex.component').then((c) => c.CoachNexComponent),
          },
          {
            path: 'coaching',
            loadComponent: () => import('./dashboard-layout/coaching/coaching.component').then((c) => c.CoachingComponent),
          },
          {
            path: 'coaching/:id',
            loadComponent: () => import('./dashboard-layout/coaching/coaching.component').then((c) => c.CoachingComponent),
          },
        ]
      },
      {
        path: 'team',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.team
        },
        loadComponent: () => import('./dashboard-layout/teams/teams.component').then((c) => c.TeamsComponent),
      },
      {
        path: 'sandbox',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.sandbox,
          isSandboxPage: true
        },
        loadChildren: () => [
          {
            path: ':id',
            loadChildren: () => [
              {
                path: '',
                loadComponent: () => import('./dashboard-layout/sandbox/sandbox.component').then((c) => c.SandboxComponent),
              },
              {
                path: 'calls',
                loadChildren: () => [
                  {
                    path: '',
                    loadComponent: () => import('./dashboard-layout/processes/process-summary/call-list/call-list.component').then((c) => c.CallListComponent),
                  },
                  {
                    path: ':callId',
                    loadComponent: () => import('./dashboard-layout/processes/process-summary/call-list/call-audit/call-audit.component').then((c) => c.CallAuditComponent),
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'admin',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.contiinexAdmin
        },
        loadChildren: () => [
          {
            path: '',
            loadComponent: () => import('./dashboard-layout/admin/admin.component').then((c) => c.AdminComponent),
          },
        ]
      },
      {
        path: 'settings',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.settings
        },
        loadChildren: () => [
          {
            path: '',
            loadComponent: () => import('./dashboard-layout/settings/settings.component').then((c) => c.SettingsComponent),
          },
          {
            path: 'groups',
            data: {
              isShowPreviousPageBtn: true,
            },
            loadComponent: () => import('./dashboard-layout/settings/groups/groups.component').then((c) => c.GroupsComponent),
          },
        ]
      },
      {
        path: 'help',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.help
        },
        loadComponent: () => import('./dashboard-layout/helps/helps.component').then((c) => c.HelpsComponent),
      },
      {
        path: 'notifications',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.notifications
        },
        loadComponent: () => import('./dashboard-layout/notification/notification.component').then((c) => c.NotificationComponent),
      },
      {
        path: 'insights',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.insights
        },
        loadComponent: () => import('./dashboard-layout/insights/insights.component').then((c) => c.InsightsComponent),
      },
      {
        path: 'change-password',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.changePassword
        },
        loadComponent: () => import('../app/dashboard-layout/change-password/change-password.component').then((c) => c.ChangePasswordComponent),
      },
      {
        path: '404',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.notFound
        },
        loadComponent: () => import('../@shared/components/nex-page-not-found/nex-page-not-found.component').then((c) => c.NexPageNotFoundComponent),
      },
      {
        path: 'profile',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.profile
        },
        loadComponent: () => import('../app/dashboard-layout/profile/profile.component').then((c) => c.ProfileComponent),
      },
      {
        path: 'superset',
        canActivate: [ roleGuard ],
        data: {
          permission: rolePermissionConfig.dashboard
        },
        loadChildren: () => [
          {
            path: '',
            loadComponent: () => import('../app/dashboard-layout/superset/superset.component').then((c) => c.SupersetComponent),
          },
          {
            path: ':id',
            loadComponent: () => import('../app/dashboard-layout/superset/superset.component').then((c) => c.SupersetComponent),
          }
        ],
      }
    ]
  },
  { path: '**', redirectTo: '/dashboard/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
