import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupersetService {
  private httpClient: HttpClient;
  public storedWindowObj:any = [];
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  private loginToSuperset(): Observable<any> {
    const reqBody = {
      username: environment.superset.username,
      password: environment.superset.password,
      provider: "db",
      refresh: true
    };
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient.post<any>(`${environment.superset.url}/api/v1/security/login`, reqBody, { headers });
  }

  private fetchGuestToken(accessToken: any, dashboardId: string): Observable <any> {
    const body = {
      resources: [{
        type: "dashboard",
        id: dashboardId,
      }],
      rls: [],
      user: {
        username: environment.superset.username,
        first_name: environment.superset.username,
        last_name: environment.superset.username,
      }
    };

    const acc = accessToken["access_token"]; //accessToken is an object in which there are two tokens access_token and refresh_token ,out of which we just need to send access_token to get guest_token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${acc}`,
    });

    //guest_token URL should end with forward_slash(/)
    return this.httpClient.post<any>(`${environment.superset.url}/api/v1/security/guest_token/`, body, { headers });
  }

  getGuestToken(dashboardId: string): Observable<any> {
    return this.loginToSuperset().pipe(
      catchError((error) => {
        console.error(error);
        return error;
      }),
      switchMap((accessToken: any) => this.fetchGuestToken(accessToken, dashboardId))
    );
  }

  embedDashboard(processId: number): Observable<any> {
    const dashboardId = ((environment.superset.processes) as any)?.[processId]?.key || '';

    return new Observable((observer) => {
      if (dashboardId) {
        this.getGuestToken(dashboardId)
          .subscribe({
            next: (token) => {
              const dashboard = document.getElementById('dashboard') as HTMLElement;
              
              embedDashboard({
                id: dashboardId, // Replace with your dashboard ID
                supersetDomain: environment.superset.url,
                mountPoint: dashboard,
                fetchGuestToken: () => token["token"],
                dashboardUiConfig: {
                  hideTitle: true,
                  hideChartControls: true,
                  hideTab: true,
                  filters: {
                    visible: false,
                    expanded: false
                  },
                },
              });
              observer.next();
              observer.complete();
            },
            error: (error) => {
              observer.error(error);
            }
          });
      } else {
        observer.error('Please check your processId');
      }
    });
  }

  storeWindowObjectOfSuperset(obj:any){
    this.storedWindowObj.push(obj);
  }
}
