/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Injectable, WritableSignal, signal } from '@angular/core';
import { Scenario, Transcript } from '../interfaces/scenario';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AgentSettings } from '../interfaces/agent-settings';
import { Observable } from 'rxjs/internal/Observable';
import { Language } from '../interfaces/language';
import { SidebarConfig } from '../interfaces/sidebar-config';
import { Process } from '../interfaces/process';
import { ProcessType } from '../interfaces/processType';
import { AuditType } from '../interfaces/auditType';
import { Attribute } from '../interfaces/attribute';
import { AuditForm } from '../interfaces/audit-form';
import { CommonService } from './common.service';
import { RoleMapping } from '../interfaces/roleMapping';
import { map } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FilterProcess } from '../interfaces/filterProcess';
import { PaginationReq, PaginationRes } from '../interfaces/pagination';
import { ProcessSummary } from '../interfaces/process-summary';
import { AudioTranscript } from '../interfaces/audio-transcript';
import { merge } from 'rxjs/internal/observable/merge';
import { of } from 'rxjs/internal/observable/of';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


const defaultData = {
  scenario: JSON.stringify({
    name: '',
    value: '',
    config: {
      sentiment: {
        visible: true,
        title: 'Sentiment',
      },
      transcripts: {
        visible: true,
        title: 'Transcripts',
      },
      idv: {
        visible: true,
        title: 'Identification & Verification',
      },
      crm: {
        visible: true,
        title: 'Form',
      },
      summary: {
        visible: true,
        title: 'Call Summary',
      },
    },
    overallSentiment: {
      polarity: '',
      emotion: '',
      intention: '',
      urgency: '',
    },
    transcripts: [],
    idv: [],
    crm: [],
    summary: '',
  } as unknown as Scenario),
  agentSettings: JSON.stringify({
    name: '---',
    designation: '---',
    img: '',
  } as AgentSettings),
  streamStatus: 'stop',
};


@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public scenario: WritableSignal<Scenario> = signal<Scenario>(JSON.parse(defaultData.scenario));
  public agentSettings: WritableSignal<AgentSettings> = signal<AgentSettings>(JSON.parse(defaultData.agentSettings));
  public streamStatus: WritableSignal<string> = signal<string>(defaultData.streamStatus);
  public mediaRecorderStatus: WritableSignal<boolean> = signal<boolean>(false);
  public isOnline: WritableSignal<boolean> = signal<boolean>(navigator.onLine);
  public sidebarConfig: WritableSignal<SidebarConfig> = signal<SidebarConfig>(this.getSidebarConfig());

  public roleMapping: WritableSignal<RoleMapping[]> = signal<RoleMapping[]>([]);
  pageScroll: CdkScrollable | null = null;

  constructor(
    private commonService: CommonService, public http: HttpClient
  ) {
    this.checkNetworkStatus();
  }

  checkNetworkStatus() {
    merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine), takeUntilDestroyed())
      .subscribe({
        next: (status: boolean) => {
          console.log('isOnline status', status);
          this.isOnline.set(status);
        },
      });
  }

  sendEmail(email = ''): Observable<void> {
    // const receive = ["sheeba@contiinex.com", "marie@contiinex.com", "pradeepdey@contiinex.com", "walter.jacobs@sunlife.com"];
    const receive = ["sheeba@contiinex.com", "marie@contiinex.com"];
    const date = new Date();
    date.setDate(date.getDate() + 1);

    if (email) {
      receive.push(email);
    }

    return this.http.post<void>(`${environment.apiUrl}/send_mail`, {
      name: "Sara Brown",
      receiver: receive,
      date: this.dateToDdMmYyyy(date),
      time: "11 AM"
    });
  }

  dateToDdMmYyyy(date: Date): string {
    const yyyy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();

    return dd.toFixed(0).toString().padStart(2, '0') + '/' + mm.toFixed(0).toString().padStart(2, '0') + '/' + yyyy;
  }
  
  postFinalSummary(scenario: Scenario): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/final_summary`, { data: { transcript: scenario.transcripts.filter((transcript: Transcript) => transcript.text).map((transcript: Transcript) => ({ text: transcript.text })), idv: scenario.idv, crm_fields: scenario.crm, promptsObject: [], summary: scenario.summary } });
  }

  postLlmTrigger(reqBody: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/llm_trigger`, reqBody);
  }

  resetSharedSignalsData(): void {
    this.scenario.set(JSON.parse(defaultData.scenario));
  }

  getProcessTypes(): Observable<ProcessType[]> {
    return this.commonService.getAll<ProcessType[]>('processTypes');
  }

  getRoleMapping(): Observable<RoleMapping[]> {
    return this.commonService.getAll<RoleMapping[]>('roleMapping').pipe(map((res) => {
      this.roleMapping.set(res);
      return res;
    }));
  }

  getAuditForms(): Observable<AuditForm[]> {
    return this.commonService.getAll<AuditForm[]>('auditForms');
  }

  paginationAuditForms(reqBody: PaginationReq): Observable<PaginationRes<AuditForm>> {
    return this.commonService.pagination<AuditForm>('auditForms/pagination', reqBody);
  }

  getLanguages(): Observable<Language[]> {
    return this.commonService.getAll<Language[]>('languages');
  }

  getAuditTypes(): Observable<AuditType[]> {
    return this.commonService.getAll<AuditType[]>('auditTypes');
  }

  auditFormNames(): Observable<AuditForm[]> {
    return this.commonService.getAll<AuditForm[]>('auditforms/name');
  } 

  getAttributes(): Observable<Attribute[]> {
    return this.commonService.getAll<Attribute[]>('attributes');
  }

  getProcesses(): Observable<Process[]> {
    return this.commonService.getAll<Process[]>('processes');
  }

  getFilterProcesses(reqBody: FilterProcess): Observable<Process[]> {
    return this.commonService.post<Process[]>('processes/data', reqBody);
  }

  getProcessSummary(reqBody: PaginationReq): Observable<ProcessSummary> {
    return this.commonService.post<ProcessSummary>('dashboardSummary/data', reqBody);
  }

  getAttributeSampleResponse(attribute: string, intent: string): Observable<string> {
    return this.commonService.post<string>('mockAPIResponse/data', { attribute, intent });
  }

  insertOrUpdateAuditForms(reqBody: AuditForm): Observable<AuditForm> {
    if (reqBody.id) {
      return this.commonService.put<AuditForm>(`auditForms/${reqBody.id}`, reqBody);
    } else {
      return this.commonService.post<AuditForm>('auditForms', reqBody);
    }
  }

  updateTranscriptsAndAuditResult(reqBody: AudioTranscript): Observable<any> {
    return this.commonService.put<any>(`transcriptsAndAuditResults/${reqBody.id}`, reqBody);
  }

  reAudit(callId: number, audioFilePath: string, fileName: string, language: string, processId: number): Observable<string> {
    return this.commonService.postByPass<string>('https://ttsl.contiinex.com/api/v1/reaudit', { call_id: callId, audio_file_path: audioFilePath, file_name: fileName, language: language, process_id: processId });
  }

  setPageScroll(pageScroll: CdkScrollable): void {
    this.pageScroll = pageScroll;
  }

  scrollTop(top = 0): void {
    this.pageScroll?.scrollTo({ top: top, behavior: 'smooth' });
  }

  toggleSidebar(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isOpen: !currentConfig.isOpen });
    this.setSidebarConfig();
  }

  openSidebar(): void {
    const currentConfig = this.sidebarConfig();
    if (!currentConfig.isOpen) {
      this.sidebarConfig.set({ ...currentConfig, isOpen: true });
      this.setSidebarConfig();
    }
  }

  closeSidebar(): void {
    const currentConfig = this.sidebarConfig();
    if (currentConfig.isOpen) {
      this.sidebarConfig.set({ ...currentConfig, isOpen: false });
      this.setSidebarConfig();
    }
  }

  minimizeAndMaximizeSidebar(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isMinimize: !currentConfig.isMinimize });
    this.setSidebarConfig();
  }

  minimizeSidebar(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isMinimize: true });
    this.setSidebarConfig();
  }

  maximizeSidebar(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isMinimize: false });
    this.setSidebarConfig();
  }

  showPreviousPageBtn(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isShowPreviousPageBtn: true });
    this.setSidebarConfig();
  }

  hidePreviousPageBtn(): void {
    const currentConfig = this.sidebarConfig();
    this.sidebarConfig.set({ ...currentConfig, isShowPreviousPageBtn: false });
    this.setSidebarConfig();
  }

  setSidebarConfig(): void {
    localStorage.setItem('sidebarConfig', JSON.stringify(this.sidebarConfig()))
  }

  getSidebarConfig(): SidebarConfig {
    const sidebarConfigStr = localStorage.getItem('sidebarConfig') || '{}';
    const sidebarConfig = JSON.parse(sidebarConfigStr);

    return (Object.keys(sidebarConfig).length > 0 ? sidebarConfig : {
      isOpen: true,
      isMinimize: false,
      isShowPreviousPageBtn: false
    }) as SidebarConfig
  }
}
