import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export const nexLoginGuard: CanActivateFn = () => {
  
  if (environment.loginAllow.includes('contiinex')) {
    return true;
  } else {
    return inject(Router).createUrlTree(['/auth/login']);
  }
};
