import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const mapping = authService.getMapping();
  const permission = route.data?.['permission'] || [];

  if (mapping.userType) {
    if (permission.includes(mapping.userType)) {
      return true;
    } else {
      authService.logout();
      return false;
    }
  } else {
    return true;
  }
};
