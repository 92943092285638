import { OktaAuthOptions } from "@okta/okta-auth-js";
import { environment } from "src/environments/environment";

export const oktaConfig: OktaAuthOptions = {
  clientId: environment.okta.clientId,
  issuer: environment.okta.issuer,
  redirectUri: environment.okta.redirectUri,
  logoutUrl: environment.okta.logoutUrl,
  scopes: environment.okta.scopes,
};
