import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { AuthType } from '../enums/authType.enum';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const isAuth = authService.isAuthorized();
  const redirectUri = authService.authType === AuthType.okta ? '/auth/login' : '/auth/nex-login';

  if (isAuth) {
    return true;
  }

  return inject(Router).createUrlTree([redirectUri]);
};
