export const rolePermissionConfig = {
  processes: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  analytics: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor'],
  auditForms: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead'],
  automate: ['Contiinex Admin', 'Admin', 'Audit Head'],
  coachNex: ['Contiinex Admin', 'Admin', 'Agent'],
  team: ['Contiinex Admin', 'Admin', 'Audit Head'],
  sandbox: ['Contiinex Admin', 'Admin'],
  settings: ['Contiinex Admin', 'Admin'],
  contiinexAdmin: ['Contiinex Admin'],
  help: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  notifications: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  insights: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  changePassword: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  notFound: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  profile: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
  dashboard: ['Contiinex Admin', 'Admin', 'Audit Head', 'Audit Lead', 'Auditor', 'Agent'],
}