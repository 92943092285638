/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/apiResponse';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { PaginationReq, PaginationRes } from '../interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private httpBackendClient: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend
  ) { 
    this.httpBackendClient = new HttpClient(handler);
  }

  getAll<T>(modelName: string): Observable<T> {
    return this.http.get<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`).pipe(map((res) => (res.success ? res.data : []) as T));
  } 

  get<T>(modelName: string): Observable<T> {
    return this.http.get<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`).pipe(map((res) => (res.success ? res.data : {}) as T));
  } 

  post<T>(modelName: string, reqBody: any): Observable<T> {
    return this.http.post<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`, reqBody).pipe(map((res) => (res.success ? res.data : {}) as T));
  } 

  put<T>(modelName: string, reqBody: any): Observable<T> {
    return this.http.put<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`, reqBody).pipe(map((res) => (res.success ? res.data : {}) as T));
  } 

  delete<T>(modelName: string): Observable<T> {
    return this.http.delete<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`).pipe(map((res) => (res.success ? res.data : {}) as T));
  } 

  pagination<T>(modelName: string, reqBody: PaginationReq): Observable<PaginationRes<T>> {
    return this.http.post<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`, reqBody).pipe(map((res) => (res.success ? res.data : {}) as PaginationRes<T>));
  } 

  upload<T>(modelName: string, reqBody: FormData): Observable<T> {
    return this.http.post<ApiResponse<T>>(`${environment.apiUrl}/${modelName}`, reqBody).pipe(map((res) => (res.success ? res.data : {}) as T));
  } 

  postByPass<T>(url: string, reqBody: any): Observable<T> {
    return this.httpBackendClient.post<ApiResponse<T>>(url, reqBody).pipe(map((res) => res as T));
  } 
}
