import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { AuthService } from '../services/auth.service';
import { mergeMap } from 'rxjs';
import { User } from '../interfaces/user';

export const dashboardResolver: ResolveFn<User> = (route, state) => {
  const sharedService = inject(SharedService);
  const authService = inject(AuthService);
  
  return sharedService.getRoleMapping().pipe(mergeMap(() => authService.getAndSetUser()));
};
