const frontendUrl = 'https://audit.contiinex.com';
const backendUrl = 'https://audit.contiinex.com';

// const frontendUrl = 'http://localhost:4200';
// const backendUrl = 'http://localhost:8000';

const coachnexSocketUrl = 'wss://audit.contiinex.com/media'; // 'ws://localhost:8020'
const insightUrl = 'https://audit.contiinex.com';
// const supersetUrl = 'https://kslsuperset.contiinex.com';
// const dashboardUrl = 'https://ksldashboard.contiinex.com';

// const insightUrl = 'https://auditnexdashboard.contiinex.com';
const dashboardUrl = 'https://auditdashboard.contiinex.com';

export const environment = {
  title: 'AuditNex',
  production: false,
  frontendUrl: frontendUrl,
  backendUrl: backendUrl,
  apiUrl: `${backendUrl}/api`,
  socketUrl: backendUrl,
  coachnexSocketUrl: coachnexSocketUrl,
  insightUrl: insightUrl,
  superset: {
    url: dashboardUrl,
    username: 'admin',
    password: 'admin',
    processes: {
      2: {
        key: '9267d188-c83e-461e-ac3f-4d7c01327df9',
        dashboard: 2
      },
      1: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      3: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      4: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      5: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      6: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      7: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      8: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      9: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      10: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      11: {
        key: 'a81b313a-a6c6-4de6-ac58-0e0dfdcfe43b',
        dashboard: 1
      },
      12: {
        key: '9343b8ee-2789-48d0-b3ba-eba36763a731',
        dashboard: 3
      },
      13: {
        key: '7fa64535-3590-4154-9309-4e72b9895dc7',
        dashboard: 4
      },
      15: {
        key: '2e859e49-110f-426a-8468-043dede44a41',
        dashboard: 6
      }
    }
  },
  featureConfig: {
    process: {
      2: {
        showMultiLanguageTranscriptOption: true,
        multiLanguageTranscriptOption1: 1,
        multiLanguageTranscriptOption2: 2,
        multiLanguageTranscriptOption3: 3,
        defaultMultiLanguageTranscriptOption: 1,
        // disableSandbox: true,
        disableReAudit: false
      },
      5: {
        showMultiLanguageTranscriptOption: true,
        multiLanguageTranscriptOption1: 3,
        multiLanguageTranscriptOption2: 8,
        multiLanguageTranscriptOption3: 4,
        defaultMultiLanguageTranscriptOption: 3,
        disableSandbox: true,
        disableReAudit: true
      },
      4: {
        disableSandbox: true,
        disableReAudit: true
      },
      3: {
        disableSandbox: false
      }
    },
    domainWise: {
      audit: {
        isDisableSocketFunctionality: true,
        disableSandbox: true,
        disableReAudit: true
      },
      localhost: {
        isDisableSocketFunctionality: true,
        // disableSandbox: true,
        disableReAudit: false,
        isDisableNotification: false
      },
      auditnex: {
        disableSandbox: true,
        disableReAudit: true
      }
    }
  },
  okta: {
    clientId: `0oadk0sw7uaWxsC6y5d7`,
    issuer: `https://dev-64266215.okta.com/oauth2/default`,
    redirectUri: `${frontendUrl}/auth/okta/callback`,
    logoutUrl: `${frontendUrl}/auth/login`,
    scopes: ['openid', 'profile', 'email', 'address', 'phone', 'userType'],
  },
  loginAllow: ['contiinex', 'okta'],
};
