import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenLoaderService {

  loading = false;
  message = '';

  show(msg = 'Loading...') {
    this.loading = true;
    this.message = msg;
  }

  hide() {
    this.loading = false;
  }
}
