import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScreenLoaderService } from 'src/@shared/services/screen-loader.service';

@Component({
  selector: 'app-nex-screen-loader',
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule
  ],
  templateUrl: './nex-screen-loader.component.html',
  styleUrls: ['./nex-screen-loader.component.scss']
})
export class NexScreenLoaderComponent {

  constructor(
    public screenLoaderService: ScreenLoaderService
  ) {}
}
